<template>
  <Content>
    <template #contentTitle>{{ $t($enumLangkey("warehouseType", pageState.warehouseType)) }}-{{ $t('menu.storage_inbound_detail') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[8, 8]" type="flex" align="middle">
          <a-col :span="24" :lg="16" :xl="18">
            <a-row :gutter="[8, 8]">
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.in_warehouse_plan_no') }}: {{ pageState.planNo || '-' }}
                <template v-if="pageState.hasException">
                  <a-tag class="ml-2" v-if="pageState.inWarehouseStatus === inWarehouseStatusEnum.complete || pageState.inWarehouseStatus === inWarehouseStatusEnum.cancel">{{ $t('common.exception') }}</a-tag>
                  <a-tag class="ml-2" color="error" v-else>{{ $t('common.exception') }}</a-tag>
                </template>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">{{ $t('warehouse.in_plan_name') }}: {{ pageState.planName || '-' }}</a-col>
              <a-col :span="24" :lg="12" :xl="8" v-if="pageState.relation">
                {{ $t('warehouse.source_related_doc_no') }}: <CPlanNumberItem :no="pageState.relation"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8" v-else-if="pageState.toRelationPlanNo">
                {{ $t('warehouse.target_related_doc_no') }}: <CPlanNumberItem :no="pageState.toRelationPlanNo"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8" v-else>
                {{ $t('warehouse.relation_no') }}: -
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.creation_time') }}: {{ $filters.utcToCurrentTime(pageState.creationTime) }}
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.update_time') }}: {{ $filters.utcToCurrentTime(pageState.lastModificationTime) }}
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.in_warehouse_type') }}: {{ $t($enumLangkey("inWarehouseType", pageState.inWarehouseType)) }}
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.in_target_warehouse') }}: 
                <span v-if="pageState.to">{{ pageState.to.no }}({{ pageState.to.name }})</span>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <a-col :span="24" :lg="12" :xl="16">
                {{ $t('warehouse.warehouse_address') }}: 
                <template v-if="pageState.to">
                  <span>{{ getFullAddress(pageState.to) }}</span>
                  <span class="ml-1">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.to) }})</span>
                </template>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <a-col :span="24" :lg="12" :xl="8">
                {{ $t('warehouse.originate') }}: 
                <span v-if="pageState.from">{{ pageState.from.name || '-' }}</span>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <a-col :span="24" :lg="12" :xl="16">
                {{ $t('warehouse.from_product_address') }}: 
                <template v-if="pageState.from">
                  <span>{{ getFullAddress(pageState.from) }}</span>
                  <span class="ml-1" v-if="pageState.from.isWarehouse">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.from) }})</span>
                </template>
                <span v-else>{{ '-' }}</span>
              </a-col>
              <template v-if="pageState.total">
                <a-col :span="24" :lg="12" :xl="8">
                  {{ $t('warehouse.planned_quantity') }}: {{ pageState.total.plan?.totalWarehouseCount ?? '-' }} Unit
                </a-col>
                <a-col :span="24" :lg="12" :xl="16">
                  {{ $t('warehouse.planned_box_quantity') }}: {{ pageState.total.plan?.totalContainerBoxCount ?? '-' }} {{ $t('warehouse.box') }}
                </a-col>
              </template>
            </a-row>
          </a-col>
          <a-col :span="24" :lg="8" :xl="6">
            <div style="text-align: center;">{{ $t('warehouse.plan_status') }}</div>
            <div style="text-align: center;">
              <span v-if="inWarehouseStatusEnum.cancel" class="text-success">{{ $t($enumLangkey('inWarehouseStatus', pageState.inWarehouseStatus)) }}</span>
              <span v-else style="color: #aaa">{{ $t($enumLangkey('inWarehouseStatus', pageState.inWarehouseStatus)) }}</span>
            </div>
          </a-col>
        </a-row>
        <a-table
          style="overflow-x: auto;"
          class="mt-3"
          :columns="columns"
          :data-source="boxList"
          size="small"
          :expandIconAsCell="false"
          :expandIconColumnIndex="2"
          :pagination="pagination"
          :row-key="(record) => record.detailId"
          @change="(page) => pagination.current = page.current"
        >
          <template #serialNumber="{ index }">
            {{ ((pagination.current - 1) * pagination.pageSize) + (index + 1) }}
          </template>
          <template #boxNoTitle>
            <div>{{ $t('warehouse.box_no') }}</div>
            <div>{{ $t('common.user_defined_box_no') }}</div>
          </template>
          <template #boxNo="{ record }">
            <div>
              {{ record.box.boxNo || '-' }}
              <CTagMixedOrSingle :productKindCount="record.box.container.productKindCount * 1"></CTagMixedOrSingle>
            </div>
            <div>
              <UserDefinedBoxNo :boxId="record.box.whBoxId" :userDefinedBoxNo="record.box.selfBoxNo"></UserDefinedBoxNo>
            </div>
          </template>
          <template #expandIcon="{ expanded, record, onExpand }">
            <CHaveProductInformation :disabled="!record.box.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
          </template>
          <template #encasementSize="{ record }">
            <span v-if="record.box.container">{{ record.box.container.containerLength }}x{{ record.box.container.containerWidth }}x{{ record.box.container.containerHeight }}cm</span>
            <span v-else>-</span>
          </template>
          <template #encasementWeight="{ record }">
            <span v-if="record.box.container">{{ gToKg(record.box.container.containerWeight) }}kg</span>
            <span v-else>-</span>
          </template>
          <template #encasementCount="{ record }">
            {{ record.box.container ? record.box.container.containerCount : '-' }} Unit
          </template>
          <template #inWarehouseStatus="{ record }">
            {{ $t($enumLangkey('inWarehouseStatus', record.inWarehouseStatus)) }}
          </template>
          <template #checkSize="{ record }">
            <span v-if="record.box.check">
              {{ record.box.check?.checkContainerLength || '-' }}x{{ record.box.check?.checkContainerWidth || '-' }}x{{ record.box.check?.checkContainerHeight || '-' }}cm
            </span>
            <span v-else>-</span>
          </template>
          <template #checkWeight="{ record }">
            <span v-if="record.box.check">{{ gToKg(record.box.check?.checkContainerWeight) }}kg</span>
            <span v-else>-</span>
          </template>
          <template #checkImgUrls="{ record }">
            <a-popover v-if="record.box.check?.checkImgUrls && record.box.check.checkImgUrls.length > 0" :getPopupContainer="trigger => trigger.parentNode">
              <template #content>
                <a-space>
                  <div v-for="(imgItem, index) in record.box.check.checkImgUrls"
                       class="table-list-img-common"
                       :key="index">
                    <c-image :src="imgItem" />
                  </div>
                </a-space>
              </template>
              <span class="text-primary" style="font-size:20px">
                <FileImageOutlined />
              </span>
            </a-popover>
            <span v-else>-</span>
          </template>
          <template #operationTitle>
            <a-button type="primary" ghost :loading="printLoadingId === 'allBox'" :disabled="!!printLoadingId" @click="handleBoxPrint()">{{ $t('common.bulk_print_box_label') }}</a-button>
          </template>
          <template #operation="{ record }">
            <a-button type="primary" ghost :loading="printLoadingId === record.detailId" :disabled="!!printLoadingId" @click="handleBoxPrint(record.detailId)">{{ $t('common.print_box_label') }}</a-button>
          </template>
          
          <template #errorState="{ record }">
             <div class="text-error" v-if="record.box.exception.currentErrorType">
              <a-row type="flex"  align="middle">
                <a-col>
                  {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.currentErrorType)) }}
                </a-col>
              </a-row>
            </div>
            <span class="text-error" v-else-if="record.box.exception.boxErrorType">
              {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.boxErrorType)) }} 
            </span>
            <span v-else>-</span>
          </template>
          <template #needOperate="{ record }">

             <!-- 需要操作 -->
            <template v-if="record.box.exception.currentErrorType">
              <template v-if="record.box.exception.currentErrorType">
                <!-- record.box.exception.currentErrorType == 1 (接收超时) : 显示产品漏发 和请求调查-->
                <a-select
                  v-if=" record.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout "
                  v-model:value="record.box.exception.timeOut.inWarehouseErrorCustomerType"
                  :placeholder="$t('common.please_select')"
                  style="width: 150px;"
                  @change="handleErrorDisposeChange(record)"
                >
                  <a-select-option :value="productErrorDisposeTypeEnum.omitSend">{{ $t($enumLangkey('productErrorDisposeType', productErrorDisposeTypeEnum.omitSend)) }}</a-select-option>
                  <a-select-option :value="productErrorDisposeTypeEnum.requestInvestigate">{{ $t($enumLangkey('productErrorDisposeType', productErrorDisposeTypeEnum.requestInvestigate)) }}</a-select-option>
                </a-select>
              </template>
            </template>
            <!-- 已操作的记录 -->
            <template v-else-if="record.box.exception.boxErrorType">
              <!-- 1 : 接收超时(漏发 和请求调查)-->
              <div v-if="record.box.exception.boxErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout">
                {{ $t($enumLangkey('productErrorDisposeType', record.box.exception.timeOut.inWarehouseErrorCustomerType)) }}
              </div>
            </template>

            <span v-else>-</span>
          </template>
          <template #title>
            <a-row :gutter="8" type="flex" justify="space-between">
              <a-col>
                <a-row :gutter="32" type="flex" align="middle">
                  <a-col>
                    {{ $t('warehouse.logistics_way') }}: 
                    <span v-if="pageState.logisticsType">{{ $t($enumLangkey('logisticsWay', pageState.logisticsType)) }}</span>
                    <span v-else>-</span>
                  </a-col>
                  <a-col>
                    {{ $t('warehouse.delivery_way') }}: 
                    <span v-if="pageState.deliveryType">{{ $t($enumLangkey('deliveryWay', pageState.deliveryType)) }}</span>
                    <span v-else>-</span>
                  </a-col>
                  <a-col v-if="pageState.isDeliver && pageState.isNeedPay">
                    <a-button type="primary" @click="handleOpenPayModal">{{ $t('finance.immediate_payment') }}</a-button>
                  </a-col>
                  <a-col>
                    <a-button type="primary" :disabled="!pageState.isDeliver" @click="handleOpenTrackModal">{{ $t('warehouse.tracking_shipment') }}</a-button>
                  </a-col>
                </a-row>
              </a-col>
              <!-- <a-col>
                <a-button type="link" @click="handleOpenProductModal">{{ $t('common.product_statistics') }}</a-button>
              </a-col> -->
            </a-row>
          </template>
          <template #footer>
             <a-row  type="flex" justify="space-between">
              <a-col :span="8">{{ $t('common.total') }}: {{ pageState.total ? pageState.total.plan.totalContainerBoxCount : '-' }}{{ $t('warehouse.box') }}</a-col>
              <a-col :span="8">
                <a-row :gutter="[16,0]">
                   <a-col>{{ pageState.total?.plan ? cmCubicToM(pageState.total.plan.totalVolume) : '-' }} m<sup>3</sup></a-col>
                   <a-col>{{ pageState.total?.plan ? gToKg(pageState.total.plan.totalWeight) : '-' }} kg</a-col>
                   <a-col>{{ pageState.total?.plan ? pageState.total.plan.totalWarehouseCount : '-' }} Unit</a-col>
                </a-row>
              </a-col>
              <a-col :span="8">
                <a-row :gutter="[16,0]">
                  <a-col v-if="pageState?.total?.check?.totalVolume > 0">{{cmCubicToM(pageState.total.check.totalVolume)}} m<sup>3</sup></a-col>
                   <a-col v-if="pageState?.total?.check?.totalWeight > 0">{{ gToKg(pageState.total.check.totalWeight) }} kg</a-col>
                   <a-col v-if="pageState?.total?.check?.totalWarehouseCount > 0">{{ pageState.total.check.totalWarehouseCount}} Unit</a-col>
                </a-row>
              </a-col>
             
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.box.items"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-row :gutter="8" type="flex" align="middle">
                  <a-col>
                    <div class="table-list-img-common">
                      <c-image
                        :src="record.productImgUrl"
                        :thumbWidth="600"
                        :thumbHeight="600"
                      />
                    </div>
                  </a-col>
                  <a-col flex="1">
                    <div>{{ record.productName }}</div>
                    <div>{{ record.productNo }}</div>
                  </a-col>
                </a-row>
              </template>
              <template #encasementCount="{ record }">
                {{ record.containerCount }} Unit
              </template>
              <template #operation="{ record }">
                <a-button type="primary" :loading="printProductLoading" ghost @click="handleProductLabelPrint(record)">{{ $t('warehouse.print_product_labels') }}</a-button>
              </template>
            </a-table>
          </template>
        </a-table>
        <CFee v-if="pageState.isNeedPay && fee" class="mt-5" :totalFee="fee.totalFee" :fees="fee.fees" />
        <CFee v-if="!pageState.isNeedPay && pageState.fee && pageState.fee.totalFee" class="mt-5" :totalFee="pageState.fee.totalFee" :fees="pageState.fee.fees" />
        <a-row class="mt-5 mb-3 mr-3" :gutter="16" type="flex" justify="end">
          <a-col v-if="pageState.isCanCancel"><a-button type="primary" ghost @click="handleCancel">{{ $t('common.cancel') }}</a-button></a-col>
          <a-col v-if="pageState.isShowOperationButton"><a-button type="primary" :loading="errorInvestigationLoading" @click="handleDoError">{{ $t('common.save') }}</a-button></a-col>
          <a-col><a-button type="primary" ghost @click="handleToList">{{ $t('warehouse.process_other_warehousing_plans') }}</a-button></a-col>
        </a-row>
      </a-spin>
      <Payment
        v-if="fee"
        ref="paymentRef"
        v-model:visible="payModalState.visible"
        :loading="payModalState.loading"
        :currencyId="fee.totalFee.currencyId"
        :orderNo="pageState.planNo"
        :unpaidSymbol="fee.totalFee.symbol"
        :unpaidSum="fee.totalFee.value"
        :payModalTitleType="9"
        @handlePayFee="handlePay"
      />
      <TrackingShipmentsModal ref="trackingShipmentsModalRef" />
      <ProductStatisticsModal ref="productStatisticsModalRef" />
      <ErrorInvestigationModal ref="errorInvestigationModalRef" @confirm="handleSetBoxRequestInvestigation" @cancel="handleCancelBoxRequestInvestigation" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, nextTick, reactive, ref, toRefs } from 'vue';
import { Button, Col, Input, Modal, Popover, Row, Select, Spin, Space, Table, Tag, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CImage from '@/views/components/CImage.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import UserDefinedBoxNo from '@/views/components/UserDefinedBoxNo.vue';
import CFee from '@/views/components/CFee.vue';
import Payment from '@/views/components/Payment.vue';
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import TrackingShipmentsModal from './components/TrackingShipmentsModal.vue';
import ProductStatisticsModal from './components/ProductStatisticsModal.vue';
import ErrorInvestigationModal from './components/ErrorInvestigationModal.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { gToKg, cmCubicToM, setFormStateValue, getAddressByLanguageV2, getLinkman } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";
import localPrint from "@/utils/localPrint";
import {
  logisticsWay as logisticsWayEnum,
  deliveryWay as deliveryWayEnum,
  inWarehouseStatus as inWarehouseStatusEnum,
  inWarehouseOperationErrorType as inWarehouseOperationErrorTypeEnum,
  productErrorDisposeType as productErrorDisposeTypeEnum,
} from '@/enum/enum.json';
import {
  getStorageDetail,
  getInWarehousePlanBoxPrint,
  getInWarehpusePlanBoxBatchPrint,
  getInWarehousePlanFee,
  payInWarehousePlan,
  updateCancelPlan,
  updateProductErrorStatus,
} from '@/api/modules/consignment/inbound.js';
import filters from "@/filters";

export default defineComponent({
  name: 'storage_inbound_detail',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATag: Tag,
    APopover: Popover,
    ASpace: Space,
    Content,
    CImage,
    CHaveProductInformation,
    CTagMixedOrSingle,
    UserDefinedBoxNo,
    CFee,
    Payment,
    CPlanNumberItem,
    TrackingShipmentsModal,
    ProductStatisticsModal,
    ErrorInvestigationModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();

    const paymentRef = ref(null);
    const trackingShipmentsModalRef = ref(null);
    const productStatisticsModalRef = ref(null);
    const errorInvestigationModalRef = ref(null);
    const replaceProductNumberModalRef = ref(null);

    const state = reactive({
      loading: false,
      pageState: {
        planId: null,
        planNo: null,
        planName: null,
        relation: null,
        toRelationPlanNo: null,
        creationTime: null,
        lastModificationTime: null,
        // 入库类型
        inWarehouseType: null,
        from: null,
        to: null,
        // 是否已经关联运输
        hasRelationTrans: false,
        // 是否可以取消
        isCanCancel: false,
        // 是否已经发运
        isDeliver: false,
        // 是否需要支付
        isNeedPay: false,
        // 是否有异常
        hasException: false,
        // 是否显示操作错误button
        isShowOperationButton: false,
        // 仓库类型
        warehouseType: null,
        // 总计
        total: null,
        // 费用
        fee: null,
        // 物流类型
        logisticsType: null,
        // 派送方式
        deliveryType: null,
        // 状态
        inWarehouseStatus: null,
      },
      // 费用
      fee: null,
      boxList: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small',
        position: 'both',
      },
      // 打印loading Id
      printLoadingId: null,
      // 打印产品标
      printProductLoading: false,
      payModalState: {
        visible: false,
        loading: false,
      },
      // 异常处理加载
      errorInvestigationLoading: false,
    });

    const columns = [
      {
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 180,
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo",
        }
      },
      { width: 50, },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.in_warehouse_status"),
        slots: {
          customRender: "inWarehouseStatus"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.in_warehouse_encasement_size"),
        slots: {
          customRender: "checkSize"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.in_warehouse_encasement_weight"),
        slots: {
          customRender: "checkWeight"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.in_warehouse_img"),
        slots: {
          customRender: "checkImgUrls"
        }
      },
       
      {
        width: 150,
        title: () => vueI18n.t("warehouse.error_state"),
        slots: {
          customRender: "errorState"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.need_operate"),
        slots: {
          customRender: "needOperate"
        }
      },
      {
        width: 150,
        slots: {
          title: "operationTitle",
          customRender: "operation"
        }
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 150,
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const getFullAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const handleOpenProductModal = () => {
      productStatisticsModalRef.value.open({ planId: state.pageState.planId, warehouseType: state.pageState.warehouseType });
    }

    // 取消
    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          const data = {
            planId: state.pageState.planId,
            warehouseType: state.pageState.warehouseType,
          }
          return updateCancelPlan(data).then(() => {
            message.success(vueI18n.t("common.succeed"));
            getDetail();
          });
        },
      });
    }

    // 取消请求调查弹窗处理结果
    const handleCancelBoxRequestInvestigation = ({ detailId }) => {
      state.boxList.forEach(item => {
        if (item.detailId === detailId) {
          item.box.exception.timeOut= {
            inWarehouseErrorCustomerType:null,
            requestInvestigation:{
              content:null,
              imgUrls:[],
            }
          };
        }
      });
    }

    // 请求调查弹窗处理结果
    const handleSetBoxRequestInvestigation = ({ detailId, requestInvestigation }) => {
      state.boxList.forEach(item => {
        if (item.detailId === detailId) {
          item.box.exception.timeOut.requestInvestigation = requestInvestigation;
        }
      });
    }

    // 箱下拉框操作
    const handleErrorDisposeChange = (record) => {
      let currentErrorType = record.box.exception.currentErrorType;
      if (currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout) {
        //接收超时的操作选择
        if (record.box.exception.timeOut.inWarehouseErrorCustomerType === productErrorDisposeTypeEnum.requestInvestigate) {
         let requestInvestigation = record.box.exception.timeOut.requestInvestigation;
          // 请求调查
          errorInvestigationModalRef.value.open(state.pageState, record.detailId, record.box.boxNo, record.trackNo, requestInvestigation);
        }
      } 
    }

    // 验证items数据
    const validateErrorData = () => {
      //验证存在异常的是否都处理了
     return  state.boxList.findIndex(x=>{
            if (x.box.exception.currentErrorType) {
              if (x.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout
              &&
              !x.box.exception.timeOut.inWarehouseErrorCustomerType
              ) {
              return true 
              }

              return false
            } else {
              return false
            }
          }) >= 0
    }

     const _getInParameter=()=>{
      let parameter=[];
      state.boxList.forEach(x=>{
        if (x.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout) {
          parameter.push({
            detailId:x.detailId,
            inWarehouseOperationErrorType:x.box.exception.currentErrorType,
            timeOut:{
              inWarehouseErrorCustomerType:x.box.exception.timeOut.inWarehouseErrorCustomerType,
              requestInvestigation:
              x.box.exception.timeOut.inWarehouseErrorCustomerType == productErrorDisposeTypeEnum.requestInvestigate ? x.box.exception.timeOut.requestInvestigation : null
            }
          })
        }
        
      })
      return parameter;
    }

    // 保存错误处理
    const handleDoError = () => {
      if (validateErrorData()) {
        message.error(vueI18n.t('warehouse.please_complete_all_exception_handling'))
        return;
      }
      const data = {
        planId: state.pageState.planId,
        warehouseType: state.pageState.warehouseType,
        items:_getInParameter(),
      }
      state.errorInvestigationLoading = true;
      updateProductErrorStatus(data).then(() => {
        message.success(vueI18n.t('common.succeed'));
        getDetail();
      }).catch(() => {}).finally(() => {
        state.errorInvestigationLoading = true;
      });
    }

    // 打印箱标
    const handleBoxPrint = async (detailId) => {
      try {
        const data = { id: state.pageState.planId }
        let printUrl = '';
        if (detailId) {
          Object.assign(data, { detailId });
          state.printLoadingId = detailId;
          printUrl = getInWarehousePlanBoxPrint(data);
        } else {
          state.printLoadingId = 'allBox';
          printUrl = getInWarehpusePlanBoxBatchPrint(data);
        }
        await localPrint(printUrl);
      } catch (error) {
      } finally {
        state.printLoadingId = null;
      }
    }

    // 打印产品标
    const handleProductLabelPrint = async (item) => {
      try {
        state.printProductLoading = true;
        await localPrint(item.productLabelUrl);
      } catch (error) {
      } finally {
        state.printProductLoading = false;
      }
    }

    // 打开追踪货件弹窗
    const handleOpenTrackModal = () => {
      const data = {
        planId: state.pageState.planId,
        warehouseType: state.pageState.warehouseType,
      }
      trackingShipmentsModalRef.value.open(data);
    }

    // 打开支付弹窗
    const handleOpenPayModal = () => {
      state.payModalState.visible = true;
      nextTick(() => {
        paymentRef.value.handleShowPayOrder();
      });
    }

    const handlePay = () => {
      state.payModalState.loading = true;
      const data = {
        planId: state.pageState.planId,
        payFee:filters.amountToFixed2(state.fee.totalFee.value),
        warehouseType: state.pageState.warehouseType,
      }
      payInWarehousePlan(data).then(() => {
        state.payModalState.visible = false;
        message.success(vueI18n.t('common.succeed'));
        getDetail();
      }).catch((err) => {
      }).finally(() => {
        state.payModalState.loading = false;
      });
    }

    const handleToList = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/storage/inbound/list');
    }

    const getDetail = () => {
      state.loading = true;
      getStorageDetail({ planId: state.pageState.planId }).then(({ result }) => {
        setFormStateValue(state.pageState, result);
        state.boxList = result.items.map(item => {
           //用于记录 接收超时 处理的异常的数据=>保存时用到
          if (!item.box.exception.timeOut &&
            item.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout 
            ) {
            item.box.exception.timeOut = {
                inWarehouseErrorCustomerType:null,
                requestInvestigation:{
                  content:null,
                  imgUrls:[],
                }
              };
          }

          //用于记录 错误商品 处理的异常的数据=>保存时用到
          if (
            item.box.exception.currentErrorType == inWarehouseOperationErrorTypeEnum.errorProduct
          ) {
            item.box.exception.errorProduct.changeProduct = {
                items:[]
                //items:[{productId,containerCount}]
            };
          }

          item.box.items.map(ite => {
            ite.parentDetailId = item.detailId;
            return ite;
          });
          return item;
        });
        if (state.pageState.isNeedPay) {
          getPlanFees();
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    const getPlanFees = () => {
      const data = {
        planId: state.pageState.planId,
        warehouseType: state.pageState.warehouseType,
      }
      getInWarehousePlanFee(data).then(({ result }) => {
        if (result) {
          state.fee = result;
        }
      }).catch(() => {})
    }

    onMounted(() => {
      if ('id' in route.params) {
        let id = route.params.id;
        state.pageState.planId = id;
        getDetail();
      }
    });

    return {
      ...toRefs(state),
      gToKg,
      cmCubicToM,
      getLinkman,
      logisticsWayEnum,
      deliveryWayEnum,
      inWarehouseStatusEnum,
      inWarehouseOperationErrorTypeEnum,
      productErrorDisposeTypeEnum,
      paymentRef,
      trackingShipmentsModalRef,
      productStatisticsModalRef,
      errorInvestigationModalRef,
      replaceProductNumberModalRef,
      columns,
      innerColumns,
      getFullAddress,
      handleOpenProductModal,
      handleCancel,
      handleCancelBoxRequestInvestigation,
      handleSetBoxRequestInvestigation,
      handleErrorDisposeChange,
      handleDoError,
      handleBoxPrint,
      handleProductLabelPrint,
      handleOpenTrackModal,
      handleOpenPayModal,
      handlePay,
      handleToList,
    }
  }
})
</script>

<style scoped>

</style>